<template>
  <div>
    <div
      class="d-flex flex-row align-items-center justify-content-between mb-8"
    >
      <h2 class="font-weight-bold my-2 mr-5">
        {{ title }}
      </h2>
      <div class="d-flex flex-row align-items-center">
        <router-link :to="{ name: 'transport-companies' }" v-slot="{ href }">
          <a
            :href="href"
            class="btn btn-light-primary btn-fixed-height font-weight-bold px-2 px-lg-5"
          >
            <span class="d-none d-md-inline">Taşımacı Firmalar</span>
          </a>
        </router-link>
      </div>
    </div>
    <TransportCompanyWizard
      v-if="data"
      wizard-type="EDIT"
      :prefilled-data="data"
    />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TransportCompanyWizard from "./components/TransportCompanyWizard.vue";

export default {
  name: "EditTransportCompany",
  components: {
    TransportCompanyWizard
  },
  computed: {
    title() {
      return this.data?.company.name || "";
    }
  },
  data() {
    return {
      companyId: this.$route.params.companyId,
      data: null
    };
  },
  async created() {
    this.get();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "EditTransportCompany" }]);
  },
  methods: {
    get() {
      ApiService.get(
        `transport-company/get.req.php?companyId=${this.companyId}`
      )
        .then(({ data }) => {
          this.data = { ...data.data };
        })
        .catch(() => {});
    }
  }
};
</script>

<style></style>
